<template>
  <section>
    <b-table
      responsive
      :fields="fields"
      :items="devices"
      show-empty
      empty-text="Sin datos para mostrar"
    >
      <template #cell(actions)="data">
        <div
          class="text-nowrap"
        >
          <feather-icon
            v-b-tooltip.hover.top="'Abrir configuración'"
            icon="SettingsIcon"
            size="18"
            class="mr-50 text-warning cursor-pointer"
            @click="promptConfigDevice(data.item)"
          />
          <feather-icon
            v-b-tooltip.hover.top="'Editar dispositivo'"
            icon="EditIcon"
            size="18"
            class="mr-50 text-info cursor-pointer"
            @click="promptEditDevice(data.item)"
          />
          <feather-icon
            v-b-tooltip.hover.top="'Eliminar dispositivo'"
            icon="TrashIcon"
            size="18"
            class="text-danger cursor-pointer"
            @click="promptDeleteDevice(data.item)"
          />
        </div>
      </template>
    </b-table>
  </section>
</template>

<script>
import { BTable, VBTooltip } from 'bootstrap-vue'

export default {
  components: {
    BTable,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    devices: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      fields: [
        {
          key: 'name',
          label: 'Nombre',
          sortable: false,
        },
        {
          key: 'uuid',
          sortable: false,
          label: 'Identificador UUID',
        },
        {
          key: 'actions',
          label: 'Acciones',
        },
      ],
    }
  },
  methods: {
    promptDeleteDevice(device) {
      this.$emit('on-delete-device', device)
    },
    promptEditDevice(device) {
      this.$emit('on-edit-device', device)
    },
    promptConfigDevice(device) {
      this.$emit('on-config-device', device)
    },
  },
}
</script>
