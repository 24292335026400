<template>
  <b-modal
    id="add-device-modal"
    hide-footer
    title="Registar dispositivo"
  >
    <device-form
      :disabled="registerDeviceLoader"
      @on-confirm="onConfirmForm"
    />
  </b-modal>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import DeviceForm from '@/views/apps/device/components/DeviceForm.vue'
import { BModal } from 'bootstrap-vue'

export default {
  components: {
    BModal,
    DeviceForm,
  },
  data() {
    return {}
  },
  computed: {
    registerDeviceLoader() {
      return this.$store.state.deviceStoreModule.loaders.registerDevice
    },
  },
  methods: {
    async onConfirmForm(deviceData) {
      try {
        await this.$store.dispatch(
          'deviceStoreModule/registerDevice',
          deviceData,
        )
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Dispositivo creado',
            icon: 'CheckIcon',
            variant: 'success',
            text: 'Los datos se han guardado correctamente',
          },
        })
        this.$emit('on-device-added', deviceData)
        this.$bvModal.hide('add-device-modal')
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Algo salió mal...',
            icon: 'XIcon',
            variant: 'danger',
            text: 'Verifica los datos introducidos y tu conexión a internet',
          },
        })
      }
    },
  },
}
</script>
