<template>
  <section>
    <b-table
      responsive
      :fields="fields"
      :items="items"
      show-empty
      empty-text="Sin datos para mostrar"
    >
      <template #cell(receivedAt)="data">
        <div
          class="text-nowrap"
        >
          {{ getDate(data.item.receivedAt) }}
        </div>
      </template>
    </b-table>
  </section>
</template>

<script>
import { BTable, VBTooltip } from 'bootstrap-vue'

export default {
  components: {
    BTable,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      fields: [
        {
          key: 'temperature',
          label: 'Temperatura',
          sortable: false,
        },
        {
          key: 'humidity',
          label: 'Humedad',
          sortable: false,
        },
        {
          key: 'ph',
          label: 'pH',
          sortable: false,
        },
        {
          key: 'brightness',
          label: 'Luminosidad',
          sortable: false,
        },
        {
          key: 'radiation',
          label: 'Radiación',
          sortable: false,
        },
        {
          key: 'conductivity',
          label: 'Conductividad',
          sortable: false,
        },
        {
          key: 'upperSensorLevel',
          label: 'Sensor de nivel superior',
          sortable: false,
        },
        {
          key: 'lowerSensorLevel',
          label: 'Sensor de nivel inferior',
          sortable: false,
        },
        {
          key: 'receivedAt',
          label: 'Marca de tiempo',
          sortable: false,
        },
      ],
    }
  },
  methods: {
    getDate(date) {
      return date ? this.$moment(date).format('LLL') : ''
    },
    promptDeleteDevice(device) {
      this.$emit('on-delete-device', device)
    },
    promptEditDevice(device) {
      this.$emit('on-edit-device', device)
    },
    promptConfigDevice(device) {
      this.$emit('on-config-device', device)
    },
  },
}
</script>
