<template>
  <b-card
    no-body
    class="card-statistics"
  >
    <b-card-header>
      <b-card-title>Panel de servomotores</b-card-title>
      <transition name="fade">
        <b-card-text
          v-show="pendingActuatorWriteLoader"
          class="mt-xl-0 mt-md-0 mt-lg-0 mt-1 mr-25 mb-0"
        >
          <b-badge variant="light-info">
            Esperando respuesta
            <feather-icon
              icon="LoaderIcon"
              class="spinner ml-25"
            />
          </b-badge>
        </b-card-text>
      </transition>
    </b-card-header>
    <b-overlay
      :show="pendingActuatorWriteLoader"
      variant="transparent"
      spinner-type="none"
    >
      <b-card-body class="statistics-body">
        <b-row>
          <b-col
            v-for="(servomotor) in actuators.servomotors"
            :key="servomotor.alias"
            md="3"
            sm="6"
            class="mb-2 mb-md-2"
          >
            <!-- ph starts -->
            <b-form-checkbox
              :checked="servomotor.interaction_active"
              name="check-button"
              switch
              inline
              @change="changeActuatorState(servomotor)"
            >
              <strong>{{ servomotor.alias }}</strong> /
              <i>{{ servomotor.initial_inclination }}º - {{ servomotor.final_inclination }}º ({{ servomotor.milliseconds_to_wait }}ms)</i>
            </b-form-checkbox>
          </b-col>
        </b-row>
        <b-row>
          <b-button
            size="sm"
            link
            variant="link"
            class="btn-tour-skip mr-1"
            @click="$router.push({ name: 'devices' })"
          >Ir a configuración
            <FeatherIcon icon="ArrowRightIcon" />
          </b-button>
        </b-row>
      </b-card-body>
    </b-overlay>
  </b-card>
</template>

<script>
import {
  BOverlay,
  BButton,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BBadge,
  BFormCheckbox,
} from 'bootstrap-vue'

export default {
  components: {
    BOverlay,
    BButton,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BBadge,
    BFormCheckbox,
  },
  computed: {
    actuators() {
      return this.$store.state.deviceStoreModule.actuators
    },
    servomotorSettings() {
      return this.$store.state.deviceSettingsModule.servomotorSettings
    },
    sensorsData() {
      return this.$store.getters['deviceStoreModule/device'].sensorsData
    },
    device() {
      return this.$store.getters['deviceStoreModule/device']
    },
    pendingActuatorWriteLoader() {
      return this.$store.state.deviceStoreModule.loaders.pendingActuatorWrite
    },
    eventsToEmit() {
      return this.$store.state.deviceStoreModule.EVENTS_TO_EMIT
    },
  },
  mounted() {
    this.fetchServomotorSettings()
  },
  methods: {
    changeActuatorState(actuator) {
      this.$store.dispatch('deviceStoreModule/setPendingActuatorWrite', true)
      this.$socket.emit(this.eventsToEmit.SET_ACTUATOR, actuator)
    },
    fetchServomotorSettings() {
      this.$store.dispatch('deviceSettingsModule/fetchServomotorSettings')
    },
    emitActuatorEvent(actuator, actuatorIndex) {
      this.$emit('on-emit-actuator-event', {
        ...actuator,
        actuatorIndex,
      })
    },
  },
}
</script>
