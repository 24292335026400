<template>
  <section id="dashboard-analytics">
    <b-row>
      <b-col md="6" />
      <b-col md="6">
        <b-form-checkbox
          :disabled="pendingActuatorWriteLoader"
          :checked="mainLoopRunning"
          name="check-button"
          switch
          inline
          class="mb-2 float-right"
          @change="onChangeMainLoop"
        >
          Secuencia principal
        </b-form-checkbox>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        <card-device-information ref="cardDeviceInformation" />
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col
        lg="6"
        sm="12"
      >
        <statistic-card-horizontal
          icon="CloudIcon"
          :statistic="`${sensorsData.temperature}°C`"
          statistic-title="Temperatura"
          color="success"
        />
        <statistic-card-horizontal
          icon="DropletIcon"
          :statistic="`${sensorsData.humidity}%`"
          statistic-title="Humedad"
          color="info"
        />
      </b-col>
      <b-col
        lg="6"
        sm="12"
      >
        <card-statistics-group @on-emit-actuator-event="emitActuatorEvent" />
      </b-col>
    </b-row>

    <b-row>
      <b-col md="12">
        <actuators-panel-card />
      </b-col>
    </b-row>

    <b-row>
      <b-col md="12">
        <servomotors-panel-card />
      </b-col>
    </b-row>

    <b-row>
      <b-col md="12">
        <devices />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol, BFormCheckbox } from 'bootstrap-vue'

import { kFormatter } from '@core/utils/filter'
import Devices from '@/views/apps/device/Devices.vue'
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
import CardStatisticsGroup from '@/views/card/card-statistic/CardStatisticsGroup.vue'
import CardDeviceInformation from '@/views/card/card-statistic/CardDeviceInformation.vue'
import ActuatorsPanelCard from '@/views/apps/device/components/ActuatorsPanelCard.vue'
import ServomotorsPanelCard from '@/views/apps/device/components/ServomotorsPanelCard.vue'

export default {
  components: {
    BFormCheckbox,
    ServomotorsPanelCard,
    ActuatorsPanelCard,
    BRow,
    BCol,
    StatisticCardHorizontal,
    Devices,
    CardStatisticsGroup,
    CardDeviceInformation,
  },
  sockets: {
    front_update_sensors(data) {
      this.$store.dispatch('deviceStoreModule/setSensorsData', data)
      this.$refs.cardDeviceInformation.updateDeviceTimeAgo()
      this.$refs.cardDeviceInformation.updateDeviceMinutesWithoutConnection()
    },
    front_update_actuators(data) {
      this.$store.dispatch('deviceStoreModule/setActuatorsData', data)
      this.$store.dispatch('deviceStoreModule/setPendingActuatorWrite', false)
    },
    front_device_measurement_saved() {
      this.$store.dispatch('deviceStoreModule/fetchMeasurementHistory')
    },
  },
  data() {
    return {
      data: {},
    }
  },
  computed: {
    mainLoopRunning() {
      return this.$store.state.deviceStoreModule.actuators.main_loop_running
    },
    pendingActuatorWriteLoader() {
      return this.$store.state.deviceStoreModule.loaders.pendingActuatorWrite
    },
    sensorsData() {
      return this.$store.getters['deviceStoreModule/device'].sensorsData
    },
    eventsToEmit() {
      return this.$store.state.deviceStoreModule.EVENTS_TO_EMIT
    },
  },
  created() {
    // data
    this.$http.get('/analytics/data').then(response => {
      this.data = response.data
    })
  },
  methods: {
    onChangeMainLoop() {
      this.$socket.emit(this.eventsToEmit.SET_MAIN_LOOP, {})
      this.$store.dispatch('deviceStoreModule/setPendingActuatorWrite', true)
    },
    emitActuatorEvent(actuator) {
      this.$socket.emit(this.eventsToEmit.SET_ACTUATOR, actuator)
      this.$store.dispatch('deviceStoreModule/setActuatorData', actuator)
    },
    kFormatter,
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
