<template>
  <div>
    <b-card v-if="false">
      <b-overlay
        no-wrap
        rounded="lg"
        variant="transparent"
        spinner-variant="primary"
        :show="registeredDevicesLoader"
      />
      <add-device-modal @on-device-added="onDeviceAdded" />
      <delete-device-modal
        :device="deviceToDelete"
        @on-device-deleted="onDeviceDeleted"
      />
      <edit-device-modal :device="deviceToEdit" />
      <h4>Historial de mediciones</h4>
      <devices-table
        :devices="registeredDevices"
        @on-delete-device="showDeleteDeviceModal"
        @on-edit-device="showEditDeviceModal"
        @on-config-device="$router.push({ name: 'device-settings', params: {deviceId: $event.id} })"
      />
    </b-card>
    <b-card>
      <b-overlay
        no-wrap
        rounded="lg"
        variant="transparent"
        spinner-variant="primary"
        :show="registeredDevicesLoader"
      />
      <add-device-modal @on-device-added="onDeviceAdded" />
      <delete-device-modal
        :device="deviceToDelete"
        @on-device-deleted="onDeviceDeleted"
      />
      <edit-device-modal :device="deviceToEdit" />
      <h4>Historial de mediciones</h4>
      <device-measure-history-table :items="measurementHistory" />
    </b-card>
  </div>
</template>

<script>
import AddDeviceModal from '@/views/apps/device/components/AddDeviceModal.vue'
import DeleteDeviceModal from '@/views/apps/device/components/DeleteDeviceModal.vue'
import EditDeviceModal from '@/views/apps/device/components/EditDeviceModal.vue'
import DevicesTable from '@/views/apps/device/components/DevicesTable.vue'
import { BCard, BOverlay } from 'bootstrap-vue'
import DeviceMeasureHistoryTable from '@/views/apps/device/components/DeviceMeasureHistoryTable.vue'

export default {
  components: {
    DeviceMeasureHistoryTable,
    BCard,
    BOverlay,
    AddDeviceModal,
    DeleteDeviceModal,
    EditDeviceModal,
    DevicesTable,
  },
  data() {
    return {
      deviceToDelete: {},
      deviceToEdit: {},
    }
  },
  computed: {
    registeredDevices() {
      return this.$store.state.deviceStoreModule.registeredDevices
    },
    registeredDevicesLoader() {
      return this.$store.state.deviceStoreModule.loaders.fetchRegisteredDevices
    },
    measurementHistory() {
      return this.$store.state.deviceStoreModule.measurementHistory
    },
  },
  mounted() {
    this.fetchRegisteredDevices()
    this.fetchMeasurementHistory()
  },
  methods: {
    async fetchRegisteredDevices() {
      await this.$store.dispatch('deviceStoreModule/fetchRegisteredDevices')
    },
    async fetchMeasurementHistory() {
      await this.$store.dispatch('deviceStoreModule/fetchMeasurementHistory')
    },
    showDeleteDeviceModal(device) {
      this.deviceToDelete = device
      this.$bvModal.show('delete-device-modal')
    },
    showEditDeviceModal(device) {
      this.deviceToEdit = device
      this.$bvModal.show('edit-device-modal')
    },
    onDeviceAdded() {
      this.fetchRegisteredDevices()
    },
    onDeviceDeleted() {
      this.fetchRegisteredDevices()
    },
  },
}
</script>
