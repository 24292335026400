<template>

  <b-card
    no-body
    class="card-statistics"
  >
    <b-card-header>
      <b-card-title>Panel de control hidráulico</b-card-title>
      <transition name="fade">
        <b-card-text
          v-show="pendingActuatorWriteLoader"
          class="mt-xl-0 mt-md-0 mt-lg-0 mt-1 mr-25 mb-0"
        >
          <b-badge variant="light-info">
            Esperando respuesta
            <feather-icon
              icon="LoaderIcon"
              class="spinner ml-25"
            />
          </b-badge>
        </b-card-text>
      </transition>
    </b-card-header>
    <b-overlay
      :show="pendingActuatorWriteLoader"
      variant="transparent"
      spinner-type="none"
    >
      <b-card-body class="statistics-body">
        <b-row>
          <b-col
            v-for="(solenoidValve, solenoidIndex) in actuators.solenoid_valves"
            :key="solenoidValve.alias"
            md="3"
            sm="6"
            class="mb-2 mb-md-0"
          >
            <!-- ph starts -->
            <b-form-checkbox
              :checked="solenoidValve.state"
              name="check-button"
              switch
              inline
              @change="changeActuatorState(solenoidValve)"
            >
              Electroválula #{{ solenoidIndex + 1 }} ({{ solenoidValve.alias }})
            </b-form-checkbox>
          </b-col>
          <b-col
            v-for="(pump, pumpIndex) in actuators.submersible_pumps"
            :key="pump.alias"
            md="3"
            sm="6"
            class="mb-2 mb-md-0"
          >
            <!-- ph starts -->
            <b-form-checkbox
              :checked="pump.state"
              name="check-button"
              switch
              inline
              @change="changeActuatorState(pump)"
            >
              Bomba sumergible #{{ pumpIndex + 1 }} ({{ pump.alias }})
            </b-form-checkbox>
          </b-col>
        </b-row>
      </b-card-body>
    </b-overlay>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BBadge,
  BFormCheckbox,
  BOverlay,
} from 'bootstrap-vue'

export default {
  components: {
    BOverlay,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BBadge,
    BFormCheckbox,
  },
  computed: {
    actuators() {
      return this.$store.state.deviceStoreModule.actuators
    },
    sensorsData() {
      return this.$store.getters['deviceStoreModule/device'].sensorsData
    },
    pendingActuatorWriteLoader() {
      return this.$store.state.deviceStoreModule.loaders.pendingActuatorWrite
    },
    device() {
      return this.$store.getters['deviceStoreModule/device']
    },
    eventsToEmit() {
      return this.$store.state.deviceStoreModule.EVENTS_TO_EMIT
    },
  },
  methods: {
    changeActuatorState(actuator) {
      this.$store.dispatch('deviceStoreModule/setPendingActuatorWrite', true)
      this.$socket.emit(this.eventsToEmit.SET_ACTUATOR, actuator)
    },
  },
}
</script>
