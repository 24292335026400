<template>
  <b-modal
    id="delete-device-modal"
    hide-footer
    title="Eliminar dispositivo"
  >
    <p class="mt-50">
      <strong>
        ¿Deseas eliminar el dispositivo "{{ device.name }}"?
      </strong>
    </p>
    <b-button
      :disabled="deleteDeviceLoader"
      size="sm"
      variant="danger"
      class="mt-50 float-right"
      @click="deleteDevice(device)"
    >
      Sí, eliminar
      <feather-icon
        class="ml-25"
        icon="TrashIcon"
      />
    </b-button>
  </b-modal>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { BModal, BButton } from 'bootstrap-vue'

export default {
  components: {
    BModal,
    BButton,
  },
  props: {
    device: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {}
  },
  computed: {
    deleteDeviceLoader() {
      return this.$store.state.deviceStoreModule.loaders.deleteDevice
    },
  },
  methods: {
    async deleteDevice(device) {
      try {
        await this.$store.dispatch(
          'deviceStoreModule/deleteDevice',
          device.id,
        )
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Dispositivo eliminado',
            icon: 'CheckIcon',
            variant: 'success',
            text: 'Los datos se han eliminado correctamente',
          },
        })
        this.$emit('on-device-deleted', device)
        this.$bvModal.hide('delete-device-modal')
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Algo salió mal...',
            icon: 'XIcon',
            variant: 'danger',
            text: 'Verifica los datos introducidos y tu conexión a internet',
          },
        })
      }
    },
  },
}
</script>
