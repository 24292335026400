<template>
  <b-modal
    id="edit-device-modal"
    hide-footer
    title="Editar dispositivo"
  >
    <device-form
      :device-edit="device"
      @on-confirm="onConfirmForm"
    />
  </b-modal>
</template>

<script>
import DeviceForm from '@/views/apps/device/components/DeviceForm.vue'
import { BModal } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BModal,
    DeviceForm,
  },
  props: {
    device: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {}
  },
  methods: {
    async onConfirmForm(device) {
      try {
        await this.$store.dispatch(
          'deviceStoreModule/updateDevice',
          device,
        )
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Dispositivo actualizado',
            icon: 'CheckIcon',
            variant: 'success',
            text: 'Los datos se han actualizado correctamente',
          },
        })
        this.$emit('on-device-updated', device)
        this.$bvModal.hide('edit-device-modal')
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Algo salió mal...',
            icon: 'XIcon',
            variant: 'danger',
            text: 'Verifica los datos introducidos y tu conexión a internet',
          },
        })
      }
    },
  },
}
</script>
