<template>
  <b-card
    no-body
    class="card-statistics"
  >
    <b-card-header>
      <b-card-title>Información del dispositivo</b-card-title>
      <b-card-text class="mt-xl-0 mt-md-0 mt-lg-0 mt-1 mr-25 mb-0">
        <b-badge :variant="connectionStatus.lightColor">
          {{ connectionStatus.text }}
          <feather-icon
            :icon="connectionStatus.icon"
            class="ml-25"
          />
        </b-badge>
      </b-card-text>
    </b-card-header>
    <b-card-body class="statistics-body">
      <b-row>
        <b-col
          v-for="item in statisticsItems"
          :key="item.icon"
          md="3"
          sm="6"
          class="mb-2 mb-md-0"
          :class="item.customClass"
        >
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar
                size="40"
                :variant="item.color"
              >
                <feather-icon
                  :class="item.iconClass"
                  size="20"
                  :icon="item.icon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h5 class="font-weight-bolder mb-0">
                {{ item.title }}
              </h5>
              <b-card-text class="font-small-3 mb-0">
                {{ item.subtitle }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
  BBadge,
} from 'bootstrap-vue'

const MINUTES_WITHOUT_CONNECTION_LIMIT = 1
const INTERVALS_TO_CHECK_CONNECTION = 60000

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BBadge,
  },
  data() {
    return {
      deviceTimeAgo: 0,
      deviceMinutesWithoutConnection: 0,
    }
  },
  computed: {
    statisticsItems() {
      return [
        {
          icon: 'CpuIcon',
          color: 'light-success',
          title: this.sensorsData.uuid?.toUpperCase(),
          subtitle: 'Identificador UUID',
          customClass: '',
          iconClass: '',
        },
        {
          icon: 'WifiIcon',
          color: 'light-info',
          title: this.sensorsData.network,
          subtitle: 'Red',
          customClass:
            'mb-2 mb-xl-0 mr-xl-0 mr-lg-2 ml-xl-0 ml-lg-5 ml-md-5 mb-lg-2',
          iconClass: '',
        },
        {
          icon: 'ServerIcon',
          color: 'light-danger',
          title: this.sensorsData.host,
          subtitle: 'Servidor',
          customClass:
            'mb-2 mb-xl-0 mr-xl-0 mr-lg-5 ml-xl-0 ml-lg-5 ml-md-5 mb-lg-2',
          iconClass: '',
        },
        {
          icon: 'ClockIcon',
          color: this.connectionStatus.lightColor,
          title: this.connectionStatus.message,
          subtitle: `Última conexión ${this.deviceTimeAgo}`,
          customClass: 'mb-2 mb-sm-0 mr-xl-0 mr-lg-5 mt-xl-0 mt-lg-2 mt-md-2',
          iconClass: this.connectionStatus.online ? '' : 'spinner',
        },
      ]
    },
    connectionStatus() {
      const deviceDisconnected = this.deviceMinutesWithoutConnection
          > MINUTES_WITHOUT_CONNECTION_LIMIT
        || this.deviceMinutesWithoutConnection < 0
      if (deviceDisconnected) {
        return {
          text: 'Sin conexión',
          icon: 'WifiOffIcon',
          color: 'danger',
          lightColor: 'light-danger',
          message: 'Esperando conexión...',
          online: false,
        }
      }
      return {
        text: 'En línea',
        icon: 'WifiIcon',
        color: 'success',
        lightColor: 'light-success',
        message: 'Dispositivo en línea',
        online: true,
      }
    },
    sensorsData() {
      return this.$store.getters['deviceStoreModule/device'].sensorsData
    },
  },
  mounted() {
    this.updateDeviceTimeAgo()
    this.updateDeviceMinutesWithoutConnection()
    setInterval(() => {
      this.updateDeviceTimeAgo()
      this.updateDeviceMinutesWithoutConnection()
    }, INTERVALS_TO_CHECK_CONNECTION)
  },
  methods: {
    updateDeviceTimeAgo() {
      this.deviceTimeAgo = this.sensorsData.timestamp
        ? this.$moment(this.sensorsData.timestamp).fromNow()
        : 'N/A'
    },
    updateDeviceMinutesWithoutConnection() {
      this.deviceMinutesWithoutConnection = this.$moment(
        this.sensorsData.timestamp,
      ).diff(Date.now(), 'minutes')
    },
  },
}
</script>
