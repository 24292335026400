<template>
  <validation-observer ref="deviceForm">
    <b-form>
      <b-form-group
        :disabled="disabled"
        label="Nombre del dispositivo"
      >
        <validation-provider
          #default="{ errors }"
          name="nombre del dispositivo"
          rules="required"
        >
          <b-form-input
            v-model="computedDevice.name"
            :state="errors.length > 0 ? false:null"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
      <b-form-group
        :disabled="disabled"
        label="UUID"
      >
        <validation-provider
          #default="{ errors }"
          name="UUID"
          rules="required|length:36"
        >
          <b-form-input
            v-model="computedDevice.uuid"
            :state="errors.length > 0 ? false:null"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
      <b-button
        :disabled="disabled"
        size="sm"
        variant="success"
        class="mt-50 float-right"
        @click="confirmForm"
      >
        Guardar dispositivo
        <feather-icon
          class="ml-25"
          icon="CheckIcon"
        />
      </b-button>
    </b-form>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, length } from '@validations'
import {
  BForm, BFormInput, BButton, BFormGroup,
} from 'bootstrap-vue'

export default {
  components: {
    BForm,
    BFormInput,
    BButton,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    deviceEdit: {
      required: false,
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      device: {
        name: '',
        uuid: '',
      },
      required,
      length,
    }
  },
  computed: {
    computedDevice() {
      return this.deviceEdit ? this.deviceEdit : this.device
    },
  },
  methods: {
    confirmForm() {
      this.$refs.deviceForm.validate().then(success => {
        if (success) {
          this.$emit('on-confirm', this.computedDevice)
        }
      })
    },
    clearForm() {
      this.device = {
        name: '',
        uuid: '',
      }
    },
  },
}
</script>
